import { MatDialogRef } from '@angular/material/dialog';

import { Component, OnInit } from '@angular/core';

import { DoAsmtPopupService } from "src/app/services/do-asmt-popup.service";

import { UserAssessmentService } from "src/app/services/user-assessment.service";




@Component({
  selector: 'app-initial-msg',
  templateUrl: './initial-msg.component.html',
  styleUrls: ['./initial-msg.component.css']
})
export class InitialMsgComponent implements OnInit {
  
  public startingLine;
  public subMessage;
  public mainMessage : string;
  public isPreOrPost;

  
  constructor(
    private doAsmtPopupService: DoAsmtPopupService,
    private userAssessmentService: UserAssessmentService,
    public dialogRef: MatDialogRef<any>
    ) { }

    public pageDisabled;
    
  ngOnInit(): void {
    this.isPreOrPost = this.doAsmtPopupService.getPreOrPost();

    if ( this.isPreOrPost === 'pre') {
      this.startingLine = 'Before you embark...'
      this.mainMessage = 'Please take the pre-assessment'
      this.subMessage = 'It will help you track your progress.'
    }
    else if (this.isPreOrPost === 'post') {
      this.startingLine = 'Congratulations, you have completed the last journey!'
      this.mainMessage = 'Please take the post-assessment'
      this.subMessage = 'You\'ll see how you\'ve grown—and help us with research.'
    }
  }
    
  onYes () {
    this.pageDisabled = true;
    this.userAssessmentService.routeToAsmt(this.isPreOrPost);
    this.dialogRef.close();
  }
}
