import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DoAsmtPopupService {

  constructor() { }

  private isPreOrPost;

  setPreOrPost (preOrPost) {
    this.isPreOrPost = preOrPost;
  }

  getPreOrPost () {
    return this.isPreOrPost;
  }
}
