import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DoAsmtPopupService } from "src/app/services/do-asmt-popup.service";



@Component({
  selector: 'app-do-asmt-popup',
  templateUrl: './do-asmt-popup.component.html',
  styleUrls: ['./do-asmt-popup.component.css']
})
export class DoAsmtPopupComponent implements OnInit {

  public isPreOrPost;


  constructor(
    public dialogRef: MatDialogRef<any>,
    private doAsmtPopupService: DoAsmtPopupService
  ) {}

  ngOnInit(): void {
    this.isPreOrPost = this.doAsmtPopupService.getPreOrPost();
  }


}
