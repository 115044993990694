<div class="container">
    <mat-sidenav-container class="tp-container">
        <mat-sidenav mode="side" [(opened)]="opened">
            <section routerLink='/dashboard' class="tp-section" [ngClass]="{'selected': router.url === '/dashboard'}">
                <mat-icon aria-hidden="false">dashboard</mat-icon>
                <span>My Journeys</span>
            </section>
            <section routerLink='/completed-journeys' class="tp-section"
            [ngClass]="{'selected': router.url === '/completed-journeys'}">
            <mat-icon aria-hidden="false">done_outline</mat-icon>
            <span>My Field Notes</span>
        </section>
        <section routerLink='/settings' class="tp-section" [ngClass]="{'selected': router.url === '/settings'}">
            <mat-icon aria-hidden="false">settings</mat-icon>
            <span>Change Password</span>
        </section>
        <section routerLink='/contact' class="tp-section" [ngClass]="{'selected': router.url === '/contact'}">
            <mat-icon aria-hidden="false">mail_outline</mat-icon>
            <span>Contact Us</span>
        </section>
        <section (click)="logout()" class="tp-section">
            <mat-icon aria-hidden="false">logout</mat-icon>
            <span>Log Out</span>
        </section>
    </mat-sidenav>
    <mat-sidenav-content [ngClass]="{'white-background': whiteBackground}">
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
<div *ngIf="!router.url.includes('/login')" class="toggle-container" (click)="toggleMenu()">
    <mat-icon>{{opened ? "menu_open" : "menu"}}</mat-icon>
</div>
</div>