import { Injectable } from '@angular/core';
import { CookieService, CookieOptions } from 'ngx-cookie';
import { SessionInfoService } from "src/app/services/session-info.service";


@Injectable({
  providedIn: 'root'
})
export class DataHolderService {

  constructor(
    private cookieService: CookieService,
    private sessionService: SessionInfoService
  ) { }


  getD (key) {
    return this.cookieService.get(key);
  }

  setD (key, value) {
    const date = new Date(((new Date).getTime() + (5 * 60 * 60 * 1000)));
    const options = {
      secure: true,
      sameSite: "strict",
      expires: date
    } as CookieOptions
    // turns them into strings
    this.cookieService.put(key, value, options)
  }

  clearD () {
    this.cookieService.removeAll();
  }
}
