import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-finished-msg',
  templateUrl: './finished-msg.component.html',
  styleUrls: ['../midprogress-msg/midprogress-msg.component.css', './finished-msg.component.css']
})
export class FinishedMsgComponent implements OnInit {

  @Output() onNewClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public dialogRef: MatDialogRef<any>,
  ) { }

  ngOnInit(): void {}

  onStart() {
    this.onNewClick.emit();
  }

}
