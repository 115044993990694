import { Injectable } from '@angular/core';
import * as _ from "lodash";
import { HttpClient } from "@angular/common/http";

import { Deck } from "src/app/models/deck";
import { Image } from "src/app/models/image";
import { FieldNoteQuestion } from "src/app/models/fieldnote-question";
import { SessionInfoService } from "src/app/services/session-info.service";

import { DataHolderService } from "src/app/services/data-holder.service";

@Injectable({
  providedIn: 'root',
})
export class JourneyService {

  private currJourneyObj = {
    journeyID: null,
    usrJourneyID: null,
    videoPath: '',
    state: null,
    timestamp: null
  }

  private userObj = {
    userID: null
  }

  
  private currDeckObj;
  
  private rawJourneyMedia;
  
  private allFieldNotePromptsArray;
  
  private allFieldNoteQuestionsArray;
  
  private allUserJourneys;
  
  
  private initialFieldNotesAnsArr; 
  
  private allImageObjectsArray;
  
  private allDeckObjectsArray;
  
  private usrSelectedImgObjs;
  
  constructor(
    private http: HttpClient, 
    private sessionService: SessionInfoService,
    private dataHolderService: DataHolderService
    ) {
  }
  
  public getAllUserJourneys() {
    return this.allUserJourneys;
  }

  public setUserID (id) {
    this.userObj.userID = parseInt(id);
    this.dataHolderService.setD('userID', this.userObj.userID);

  }

  public getUserID () {
    return this.userObj.userID;
  }

  public setJourneyID (id) {
    this.currJourneyObj.journeyID = parseInt(id);
    this.dataHolderService.setD('journeyID', this.currJourneyObj.journeyID);
  }

  public getJourneyID () {
    return this.currJourneyObj.journeyID;
  }

  public getUsrJourneyID () {
    return this.currJourneyObj.usrJourneyID;
  }

  public setUsrJourneyID (usrJourneyID) {
    this.currJourneyObj.usrJourneyID = parseInt(usrJourneyID);
    this.dataHolderService.setD('usrJourneyID', this.currJourneyObj.usrJourneyID);
  }

  public setJourneyTimestamp (timestamp) {
    this.currJourneyObj.timestamp = parseFloat(timestamp);
    this.dataHolderService.setD('timestamp', this.currJourneyObj.timestamp);
  }

  public setJourneyState (state) {
    this.currJourneyObj.state = state;
    this.dataHolderService.setD('state', this.currJourneyObj.state);
  }

  public getJourneyTimestamp () {
    return this.currJourneyObj.timestamp;
  }

  public getJourneyState () {
    return this.currJourneyObj.state;
  }

  /**
   * This method checks if there is a userID value. If there isn't one, it gets the value from localStorage. If there is nothing stored there,
   * it returns false. Otherwise, it returns true.
   */

   getSessionUserID () {
    if (!this.userObj.userID) {
      const sessionID = parseInt(this.dataHolderService.getD('userID'));
      if (sessionID) {
        this.userObj.userID = sessionID;
        return true;
      }
      return false;
    }
    return true;
  }

  /**
   * This method checks if there is a journeyID value. If there isn't one, it gets the value from localStorage. If there is nothing stored there,
   * it returns false. Otherwise, it returns true.
   */

  getSessionJourneyID () {
  if (!this.currJourneyObj.journeyID) {
    const sessionID = parseInt(this.dataHolderService.getD('journeyID'));
    if (sessionID) {
      this.currJourneyObj.journeyID = sessionID;
      return true;
    }
    return false;
  }
  return true;
}

/**
   * This method checks if there is a journeyID value. If there isn't one, it gets the value from localStorage. If there is nothing stored there,
   * it returns false. Otherwise, it returns true.
   */

 getSessionUsrJourneyID () {
  if (!this.currJourneyObj.usrJourneyID) {
    const sessionID = parseInt(this.dataHolderService.getD('usrJourneyID'));
    if (sessionID) {
      this.currJourneyObj.usrJourneyID = sessionID;
      return true;
    }
    return false;
  }
  return true;
}

getSessionJourneyState () {
  if (!this.currJourneyObj.state) {
    if (this.dataHolderService.getD('state')) {
      this.currJourneyObj.state = this.dataHolderService.getD('state');
      return true;
    }
    return false;
  }
  return true;
}

/**
 * 
 * A journey might reasonably not have a timestamp, so a null value still returns true.
 */

getSessionTimestamp () {
  if (!this.currJourneyObj.timestamp) {
    if (this.dataHolderService.getD('timestamp')) {
      this.currJourneyObj.timestamp = parseFloat(this.dataHolderService.getD('timestamp'));
    }
  }
  return true;
}



  /**
   * This method fetches all journeys for given user.
   * It is called to initialize or refresh rawAllUserJourneys.
   */
  async initAllUserJourneys() {
    this.allUserJourneys = await this.sessionService.transaction([this.userObj.userID], "f593f0d9-1c33-4f03-accf-d2c9838c09d6", this.getUserID())
    .then(data => data.get_user_journeys);
  }

  /**
   * This method makes a new user journey, refreshes/ initializes allUserJourneys using initAllUserJourneys() and sets the new user journey id
   */

  async makeNewUserJourney() {
    let newJourneyObj = {
      datetime: new Date().toISOString(),
      journeyID: this.currJourneyObj.journeyID,
      userID: this.userObj.userID,
      state: 'start',
      timestamp: 0
    }
    await this.sessionService.transaction(newJourneyObj, "7900b9bd-1967-4940-bd2c-7255511adc01", this.getUserID());
  }

  /**
   * This method refreshes/ initializes allUserJourneys and loads usrJourneyID, selected images, field note answers,
   * from the last-started user journey of a given journey.
   */

  async loadLastJourney() {
    await this.initAllUserJourneys();
    const filJourneys = this.allUserJourneys
                        .filter(journey => journey.journeyID === this.currJourneyObj.journeyID);
    const lastUserJourney = filJourneys[filJourneys.length - 1];
    this.setUsrJourneyID(lastUserJourney.usrJourneyID);
    this.setJourneyState(lastUserJourney.state);
    this.setJourneyTimestamp(lastUserJourney.timestamp);
  }
  
  /**
   * This is called by the journey component to initialize previously selected images
   */
  async setInitialSelectedImgs () {
    const data = await this.sessionService.transaction([this.currJourneyObj.usrJourneyID], "32a19366-47fd-49a4-8a67-653d2d57c4f3", this.getUserID());
    this.usrSelectedImgObjs = data.get_usr_sel_img_objs;
    if (!this.usrSelectedImgObjs) {
      this.usrSelectedImgObjs = [];
    } 
  }

  /**
   * This method fetches field note answers for a given user journey and assigns the array to fieldNotesAnsArr
   */

  async setFieldNotesAnsArr () {
    const data = await this.sessionService.transaction([this.currJourneyObj.usrJourneyID], "9d87fa04-8f93-47ee-8052-433e30105154", this.getUserID());
    this.initialFieldNotesAnsArr = data.get_existing_fn_ans;
    if (!this.initialFieldNotesAnsArr) {
      this.initialFieldNotesAnsArr = [];
    }
  }


  getInitialFieldNotesAnsArr() {
    return this.initialFieldNotesAnsArr;
  }

  /**
   * This method updates a user journey in the backend. Parameter 'state' is required, while 'timestamp' is not.
   */
  async updateUserJourney(newState, newTimestamp) {
    let editObject = {
      datetime: new Date().toISOString(),
      state: newState,
      timestamp: 0,
      usrJourneyID: this.getUsrJourneyID()
    }
    if (newTimestamp) {
      editObject.timestamp = newTimestamp;
      this.setJourneyTimestamp(newTimestamp);
    }
    this.setJourneyState(newState);
    await this.sessionService.transaction(editObject, "e9d3644c-dfc7-417e-8a81-799b3b3cb0ab", this.getUserID());
  }

  async getJourneys() {
    let journeyObjs = await this.sessionService.transaction("", "50e156a8-266b-45e2-867a-ed510be02fec", this.getUserID())
    .then(data => {
      return data.get_journeys;
    })
    journeyObjs = journeyObjs.slice(1)
    return journeyObjs;
  }

  /**
   * This method fetches the journey media (including images, prompts, decks, field
   * note questions and prompts, and user selected images) from the backend and initializes
   * all the necessary member variables.
   */
  async initCurrentJourneyMedia() {
    this.rawJourneyMedia = await this.sessionService.transaction([this.currJourneyObj.journeyID], "f190a93e-3fe6-4b77-beac-213a04f2d875", this.getUserID())
    .then(data => {
      return data.get_journey_media;
    });
    
    this.allImageObjectsArray = this.rawJourneyMedia.allImageObjectsArray.map(obj => {
      
      obj['selected'] = false;
      obj['userID'] = this.userObj.userID;
      obj['usrJourneyID'] = this.currJourneyObj.usrJourneyID;
      
      return obj;
    });
    
    this.allFieldNotePromptsArray = this.rawJourneyMedia.allFieldNotePromptsArray;
    
    this.allFieldNoteQuestionsArray = this.rawJourneyMedia.allFieldNoteQuestionsArray.map(obj => {
      obj['userID'] = this.userObj.userID;
      obj['usrJourneyID'] = this.currJourneyObj.usrJourneyID;
      return obj;
    });
    
    this.allDeckObjectsArray = this.rawJourneyMedia.allDeckObjectsArray;
    
    this.initAllImgObjArr();
  }

  /**
   * This is called in the JourneyPage Component. This function returns all of the
   * Deck Objects to be used for the current journey. In other words, the length of this
   * array is equal to the amount of times the JourneyPopUp Component will be used for
   * the current journey.
   *
   * @returns An array of objects of type DeckObject
   */
  public getAllDeckObjsArr():Deck[] {

    return this.allDeckObjectsArray;
  }


  /**
   * This function is called from the JourneyPage Component or from the UserFieldNotes
   * Component so that this service can receive the current DeckObject.
   *
   * @param deckObjID Integer denoting the ID for the current DeckObject
   */
  public setCurrDeckObj(deckObjID) {
    for (let deckObj of this.allDeckObjectsArray) {
      if (deckObj.deckID === deckObjID) this.currDeckObj = deckObj;
    }
  }


  /**
   * This function returns the current DeckObject to the JourneyPopUp Component
   * or the UserFieldNotes Component.
   *
   * @return The current DeckObject being used in the Journey
   */
  public getCurrDeckObj():Deck {
    return this.currDeckObj;
  }


  /**
   * This function returns the current ImageObject Array that corresponds to the
   * current DeckObject. It is called from the JourneyPopUp Component (SelectImages).
   *
   * @returns An Array of objects of type ImageObject that correspond to the current DeckObject
   */
  public getCurrImgObjArr():Image[] {

    let arr = _.cloneDeep(
      this.allImageObjectsArray.filter(
        (imgObj) => this.currDeckObj.deckID === imgObj.deckID
      )
    );
    return arr;
  }

  /**
   * This function receives an array of Image IDs and the current DeckObject's ID.
   * This function then edits/updates the existing allImageObjectsArray variable
   * to switch the user selected images "selected" property to "true" or "false"
   *
   * The Array is updated so that the correct pictures show in the field notes.
   *
   * This function is called in the ReviewSelection Component
   *
   *  @param selectedImgIDs An array of imgIDs of user selected images
   *  @param deckID The ID of the current DeckObject
   */
  public updateImgObjArr(selectedImgIDs, deckID) { //TODO: fix to not include deckID (take from currDeckObj)

    this.allImageObjectsArray.map((imgObj) => {
      if (selectedImgIDs.includes(imgObj.imgID) && deckID === this.currDeckObj.deckID) {
        imgObj.selected = true;
      } else if (
        imgObj.selected === true &&
        !selectedImgIDs.includes(imgObj.imgID) &&
        imgObj.deckID === deckID
      ) {
        imgObj.selected = false;
      }
      return imgObj;
    });
  }


  public createOrUpdateBackend (selectedImgObjs) {
    const existingImgArr = this.usrSelectedImgObjs.filter(obj => obj.deckID === this.currDeckObj.deckID)

    const existingImgMap = new Map(existingImgArr.map(obj => [obj.imgID, obj.usrSelectedImgObjID] as [number ,number]));
    const selImgMap = new Map(selectedImgObjs.map(obj => [obj.imgID, obj.selected] as [number, boolean]));

    let newImgs = [];
    let editImgs = [];

    selectedImgObjs.forEach(currImg => {
      if (existingImgMap.has(currImg.imgID)) {
        currImg['usrSelectedImgObjID'] = existingImgMap.get(currImg.imgID);

        if (!('datetime' in currImg)) currImg['datetime'] = new Date().toISOString();

        delete currImg['imgID'];
        delete currImg['deckID'];
        delete currImg['journeyID'];

        editImgs.push(currImg);
      }
      else newImgs.push(currImg);
    });

    existingImgArr.forEach(existingImg => {
      if (!selImgMap.has(existingImg.imgID)) {
        existingImg.selected = false;
        existingImg['datetime'] = new Date().toISOString();

        delete existingImg['imgID'];
        delete existingImg['deckID'];
        delete existingImg['journeyID'];

        editImgs.push(existingImg);
      }
    });

  if (newImgs.length > 0 && editImgs.length === 0) {
    this.sessionService.transaction(newImgs, "83f6877d-5b9a-4e3d-876e-1db92bd47244", this.getUserID()).then(res => {
      this.sessionService.transaction([this.currJourneyObj.usrJourneyID], "32a19366-47fd-49a4-8a67-653d2d57c4f3", this.getUserID())
        .then(data => {
          this.usrSelectedImgObjs = data.get_usr_sel_img_objs;
        });
    });
  } else if (editImgs.length > 0 && newImgs.length === 0) {
    this.sessionService.transaction(editImgs, "3353caaf-4521-42b7-b629-0a19c895f6d9", this.getUserID()).then(res => {
      this.sessionService.transaction([this.currJourneyObj.usrJourneyID], "32a19366-47fd-49a4-8a67-653d2d57c4f3", this.getUserID())
        .then(data => {
          this.usrSelectedImgObjs = data.get_usr_sel_img_objs;
        });
    });
  } else { //mix of both new and existing images
    this.sessionService.transaction(newImgs, "83f6877d-5b9a-4e3d-876e-1db92bd47244", this.getUserID()).then(res => {
      this.sessionService.transaction(editImgs, "3353caaf-4521-42b7-b629-0a19c895f6d9", this.getUserID()).then(res2 => {
        this.sessionService.transaction([this.currJourneyObj.usrJourneyID], "32a19366-47fd-49a4-8a67-653d2d57c4f3", this.getUserID())
          .then(data => {
            this.usrSelectedImgObjs = data.get_usr_sel_img_objs;
          });
      });
    });
  }

  }

  /**
   * This method returns the usrSelectedImgObjs, which is initiated when loading a journey
   * and then updated everytime the backend is updated
   */

  getUsrSelectedImgObjs () {
    return this.usrSelectedImgObjs; 
  }


  /**
   * This method is called once, right after the journey media is fetched from the backend.
   * This method is meant to update the allImageObjectsArray if the user had already taken
   * the journey and made selections. Or in the case, they refreshed their browser.
   * It updates the allImageObjectsArray to change the 'selected' value to the user's
   * selected values.
   */
  private initAllImgObjArr () {
    this.allImageObjectsArray.map(imgObj => {
      this.usrSelectedImgObjs.map(selObj => {
        if (imgObj.imgID === selObj.imgID) imgObj.selected = selObj.selected;
      });
    });
  }



  /**
   * This function is called in the UserFieldNotes Component, and returns
   * an array of the user's selected image objects.
   *
   * @return Array of ImageObjects that the user has selected
   */
  public getAllUserSelectedImgObjs() {

    return this.allImageObjectsArray.filter((imgObj) => imgObj.selected === true);
  }


  /**
   *  This function is called in the UserFieldNotes Component, and returns
   *  an array of FieldNotePrompt objects associated with the current Journey.
   *
   *  @return Array of FieldNotePrompt objects associated with the current Journey
   */
  public getFieldNotesPromptsArr() {

    return this.allFieldNotePromptsArray;
  }

  /**
   *  This function is called in the UserFieldNotes Component, and returns
   *  an array of FieldNoteQuestion Objects associated with the current Journey.
   *
   *  @return Array of FieldNoteQuestion objects associated with the current Journey
   */
  public getFieldNotesQuestionsArr():FieldNoteQuestion[] {

    return this.allFieldNoteQuestionsArray;
  }

  /** This method stores and returns the hard-coded values and labels of the general questions at the end of each field notes */

  public getGeneralQoptions () {
    const generalQoptions = [
      {
        value: '1',
        label: 'Definitely false'
      },
      {
        value: '2',
        label: 'Mostly false'
      },
      {
        value: '3',
        label: 'Somewhat false'
      },
      {
        value: '4',
        label: 'Slightly false'
      },
      {
        value: '5',
        label: 'Slightly true'
      },
      {
        value: '6',
        label: 'Somewhat true'
      },
      {
        value: '7',
        label: 'Mostly true'
      },

      {
        value: '8',
        label: 'Definitely true'
      }
    ]
    return generalQoptions;
  }

}
