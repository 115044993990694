import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
{ path: '', pathMatch: "full", loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
{ path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
{ path: 'reset-password', loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
{ path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule) },
{ path: 'settings', loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule) },
{ path: 'tutorial', loadChildren: () => import('./pages/tutorial/tutorial.module').then(m => m.TutorialModule) },
{ path: 'journey/:id', loadChildren: () => import('./pages/journey/journey.module').then(m => m.JourneyModule) },
{ path: 'completed-journeys', loadChildren: () => import('./pages/completed-journeys/completed-journeys.module').then(m => m.CompletedJourneysModule) },
{ path: 'contact', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule) },
{ path: 'customer-service', loadChildren: () => import('./pages/customer-service/customer-service.module').then(m => m.CustomerServiceModule) },
{ path: 'admin-dashboard', loadChildren: () => import('./pages/admin-dashboard/admin-dashboard.module').then(m => m.AdminDashboardModule) },
{ path: 'error', loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorModule) },
{ path: '404', loadChildren: () => import('./pages/not-found/not-found.module').then(m => m.NotFoundModule) },
{ path: 'assessment', loadChildren: () => import('./pages/user-assessment/user-assessment.module').then(m => m.UserAssessmentModule) },
{ path: 'journey/:id/notes', loadChildren: () => import('./pages/user-field-notes/user-field-notes.module').then(m => m.UserFieldNotesModule) },
{path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
