import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {DataHolderService} from '../../services/data-holder.service';
import {SessionInfoService} from '../../services/session-info.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { JourneyService } from "src/app/services/journey.service";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  public currPage : Number = 0;
  public whiteBackground : boolean;
  public navVisible;
  private subscription;
  public sideNav;
  public opened : boolean;
  private warningAlreadyDisplayed: boolean;
  private smallWindowSnack;
  private recommendedDisplay = 900;
  
  constructor(
    public router: Router,
    public dataHolderService: DataHolderService,
    public sessionInfoService: SessionInfoService,
    private snackBar: MatSnackBar,
    private journeyService: JourneyService
    ) {}

  ngOnInit(): void {

    this.subscription = this.router.events.subscribe(event => {
      const url = this.router.url;

      // control background
      if(
        url.includes('/settings') ||
        url.includes('/assessment') ||
        url.includes('/404') ||
        url.includes('/contact') ||
        url.includes('/customer-service') ||
        url.includes('/error')
        ) {
          this.whiteBackground = true;
      } else {
          this.whiteBackground = false; 
        }  

      // nav visibility
      if(
        url.includes('/dashboard') || 
        url.includes('/completed-journeys') ||
        url.includes('/settings') ||
        url.includes('/contact') ||
        url.includes('/customer-service')
        ) {
          this.opened = true;
      } else {
        this.opened = false;
      }
    });

  }

  toggleMenu() {
    this.opened = !this.opened;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async logout() {
    try {
      this.dataHolderService.clearD();
      await this.sessionInfoService.logout();
      this.journeyService.setUserID(null);
      this.router.navigate(['/login']);
    } catch {
      this.snackBar.open('Failed to terminate session!', 'x');
    }
  }

}
