import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { JourneyService } from "src/app/services/journey.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-midprogress-msg',
  templateUrl: './midprogress-msg.component.html',
  styleUrls: ['./midprogress-msg.component.css']
})
export class MidprogressMsgComponent implements OnInit {

  @Output() onNewClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public dialogRef: MatDialogRef<any>,
    private journeyService: JourneyService,
    private router : Router
  ) { }

  public pageDisabled;

  ngOnInit(): void {}

  onStartOver() {
    this.onNewClick.emit();
  }

  onContinue() {
    try {
      this.pageDisabled = true;
      this.journeyService.loadLastJourney()
      .then(()=> {
        const state = this.journeyService.getJourneyState();
        if (state === 'fn') {
          this.router.navigate(['/journey', this.journeyService.getJourneyID(), 'notes']);
        }
          else if (state === 'start' || state === 'video') {
            this.router.navigate(['/journey', this.journeyService.getJourneyID()]);
          }
      })
    } catch {
      this.router.navigate(['/error']);
    } finally {
      this.dialogRef.close();
    }

  }

}
