<div class="container">
  <!-- <h1 class="font-bold" style="color: var(--color-light);">{{deckObj.deckPrompt}}</h1> -->
  <span class="font-bold title" style="color: var(--color-light);">{{deckObj.deckPrompt}}</span>
  <h2 style="color: var(--color-light);">Review your selection</h2>
  <div class="imgList">
    <div class="imageContainer" *ngFor="let idx of objectKeys(currSelectedImgObjs)">
      <img (load)="imageLoaded()" class="image faster-fade-in" src="https://d4m4k0kqy6vns.cloudfront.net/media/deck-images/{{deckObj.journeyID}}/{{deckObj.deckID}}/{{currSelectedImgObjs[idx].imagePath}}.jpg">
    </div>
  </div>
  <div class="container-footer" [ngClass]="{'disabled': pageDisabled}">
    <button class="button" mat-flat-button color="primary" (click)="goBack()">Back</button>
    <button class="button" mat-flat-button color="primary" (click)="onSubmit()">Submit</button>
  </div>
</div>
