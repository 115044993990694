import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';

import { JourneyService } from "src/app/services/journey.service";

@Component({
  selector: 'app-select-images',
  templateUrl: './select-images.component.html',
  styleUrls: ['./select-images.component.css']
})
export class SelectImagesComponent implements OnInit {

  @Input('tempImgObjArr') currSelectedImgObjs;
  @Output() onReviewClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onLoadChanged: EventEmitter<any> = new EventEmitter<any>();

  public imageObjects;
  public deckObject;
  public noImages;
  private loadingCounter = 0;

  constructor(private journeyService: JourneyService) { }

  ngOnInit(): void {
    // determine whether the user has already selected images
    if(this.currSelectedImgObjs !== undefined) this.imageObjects = this.journeyService.getCurrImgObjArr().map(obj => this.currSelectedImgObjs.find(o => o.imgID === obj.imgID) || obj);
    else this.imageObjects = this.journeyService.getCurrImgObjArr();
    this.deckObject = this.journeyService.getCurrDeckObj();
  }

  toggleSelect (targetID) {
    this.noImages = false;
    this.imageObjects.map(obj => {
      if (obj.imgID === Number(targetID)) {
        obj.selected = !obj.selected;
        obj.datetime = new Date().toISOString();
      }
      return obj;
    });
  }

  objectKeys (obj) {
    return Object.keys(obj);
  }

  numSelected () {
    return this.imageObjects.reduce((acc, obj) => {
      if (obj.selected) acc++;
      return acc;
    },0);
  }

  selectedObjects () {
    return this.imageObjects.filter(obj => obj.selected === true);
  }

  onReset () {
    this.imageObjects.forEach(imgObj => imgObj.selected = false);
  }

  onReview () {
    let selectedImgObjs = this.imageObjects.filter(imgObj => imgObj.selected === true);

    if (selectedImgObjs.length !== 0) {
      this.onLoadChanged.emit(false);
      this.onReviewClick.emit(selectedImgObjs);
    } else {
      this.noImages = true;
    }

  }

  imageLoaded () {
    this.loadingCounter += 1;
    if (this.loadingCounter >= this.imageObjects.length || this.loadingCounter >= 10) {
      this.onLoadChanged.emit(true);
    }
  }

}
