import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-journey-pop-up',
  templateUrl: './journey-pop-up.component.html',
  styleUrls: ['./journey-pop-up.component.css']
})
export class JourneyPopUpComponent implements OnInit {

  public userClickedReview;
  public userClickedBack;

  public tempSelectedImgIds;

  public isVisible: boolean;

  constructor() {}

  ngOnInit(): void {
    this.userClickedBack = false;
    this.userClickedReview = false;
  }

  reviewClicked (e) {
    this.userClickedBack = false;
    this.userClickedReview = !this.userClickedReview;
    this.tempSelectedImgIds = e;
  }

  backClicked () {
    this.userClickedReview = false;
    this.userClickedBack = !this.userClickedBack;
  }

  changeVisibility (e) {
    if (e) {
      this.isVisible = true;
      return;
    }
    this.isVisible = false;
  }

}
