import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ExistingJourneyPopupService {

  private isMidprogressOrFinished;

  constructor() { }

  setIsMidprogressOrFinished (midprogressOrFinished) {
    this.isMidprogressOrFinished = midprogressOrFinished;
  }

  getIsMidprogressOrFinished () {
    return this.isMidprogressOrFinished;
  }
}
