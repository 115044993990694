import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: "https://dab9d350f1ca95460cd4d85d9b78e871@o4507498727342080.ingest.us.sentry.io/4507506630066176",
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
      networkCaptureBodies: true,
      networkDetailAllowUrls: [window.location.href, window.location.origin, /https\:\/\/server\.herosjourneyinteractive\.com.*/, /https\:\/\/middleware\.herosjourneyinteractive\.com.*/]
    }),
  ],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

enableProdMode();
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

  setTimeout(() => {
    throw new Error("Sentry Test Error");
  });