import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { ExistingJourneyPopupService } from 'src/app/services/existing-journey-popup.service';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-existing-journey-popup',
  templateUrl: './existing-journey-popup.component.html',
  styleUrls: ['./existing-journey-popup.component.css']
})
export class ExistingJourneyPopupComponent implements OnInit {

  @Output() onNewClick: EventEmitter<any> = new EventEmitter<any>();

  public isMidprogressOrFinished;

  constructor(
    private existingJourneyPopupService: ExistingJourneyPopupService,
    public dialogRef: MatDialogRef<any>
  ) {}

  ngOnInit(): void {
    this.isMidprogressOrFinished = this.existingJourneyPopupService.getIsMidprogressOrFinished();
  }

  emitOnNewClick() {
    this.onNewClick.emit();
  }
}
