import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class SessionInfoService {

  private Server = 'https://server.herosjourneyinteractive.com';
  private Middleware = 'https://middleware.herosjourneyinteractive.com';

  /**
   * @class SessionInfoService
   */
  constructor(
    private router : Router
  ) { }

  public getServer() {
    return this.Server;
  }


  /**
   * @method transaction
   * @async
   * @param data {any} data to be passed to backend
   * @param id {string} id of fetch request
   * @returns json message or data from fetch
   */
  async transaction(data, id, userID) {

    const packet = {
      id: id,
      packet: data,
      userID: userID
    }

    const response = await fetch(`${this.Server}/transaction`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(packet)
    });

    if(response.status === 200) {
      const responseJSON = await response.json();
      return responseJSON;
    } else if (response.status === 401) {
      // if session is missing or expired, throw them to login screen
      this.router.navigate(['/login']);
    } else {
      throw new Error(response.status.toString())
    }
  }

  async dashboard(key) {
    const response = await fetch(`${this.Server}/admin-dashboard`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ adminKey: key })
    });

    if(response.status === 200) {
      const responseJSON = await response.json();
      return responseJSON;
    } else {
      throw new Error(response.status.toString())
    }
  }

  /**
   * @method login
   * @async
   * @param userEmail {string} the current user's email
   * @param userPassword {string} the current user's password
   */
  async login (userEmail, userPassword) {
    const info = {
      email: userEmail,
      password: userPassword
    };

    const response = await fetch(`${this.Server}/login`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(info)
    });

    if (response.status === 200) {
      const userID = await response.json();
      return userID;
    } else {
      throw 'fail'
    }

  }

  async logout () {

    try {
      const response = await fetch(`${this.Server}/logout`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      if (response.status === 200) {
        return true;
      } else {
        throw 'failed to log out'
      }
      
    } catch (e) {
      console.error(e);
    }

  }

  async resetPassword (email) {
    const response = await fetch(`${this.Middleware}/reset-password`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: email })
    });

    if (response.status === 200) {
      return true
    } else {
      throw 'failed to reset password'
    }
  }
   
}
