import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { CookieModule } from 'ngx-cookie';

import { MatButtonModule } from "@angular/material/button";
import { MatRadioModule } from "@angular/material/radio";
import { MatDividerModule } from '@angular/material/divider';
import {TextFieldModule} from '@angular/cdk/text-field';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar';

import { AppComponent } from './app.component';
import { JourneyPopUpComponent } from './components/journey-pop-up/journey-pop-up.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReviewSelectionComponent } from './components/journey-pop-up/review-selection/review-selection.component';
import { SelectImagesComponent } from './components/journey-pop-up/select-images/select-images.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { DoAsmtPopupComponent } from './components/do-asmt-popup/do-asmt-popup.component';
import { InitialMsgComponent } from './components/do-asmt-popup/initial-msg/initial-msg.component';
import { ExistingJourneyPopupComponent } from './components/existing-journey-popup/existing-journey-popup.component';
import { MidprogressMsgComponent } from './components/existing-journey-popup/midprogress-msg/midprogress-msg.component';
import { FinishedMsgComponent } from './components/existing-journey-popup/finished-msg/finished-msg.component';
import {AppRoutingModule} from './app-routing.module';


/* To test new components, add component to test in component section and route to: /test */
// const appRoutes: Routes = [
//   { path: '', component: LoginComponent, data: { title: 'login' }, pathMatch: "full"},
//   { path: 'login', component: LoginComponent, data: { title: 'login' } },
//   { path: 'dashboard', component: DashboardComponent, data: { title: 'dashboard' } },
//   { path: 'settings', component: SettingsComponent, data: { title: 'settings' } },
//   { path: 'tutorial', component: TutorialComponent, data: { title: 'tutorial' } },
//   { path: 'journey/:id', component: JourneyComponent, data: { title: 'journey' } },
//   { path: 'journey/:id/notes', component: UserFieldNotesComponent, data: { title: 'user-field-notes' } },
//   { path: 'completed-journeys', component: CompletedJourneysComponent, data: { title: 'completed-journeys' } },
//   { path: 'assessment', component: UserAssessmentComponent, data: { title: 'user-assessment' } },
//   { path: 'contact', component: ContactComponent, data: { title: 'contact-us' } },
//   { path: 'customer-service', component: CustomerServiceComponent, data: { title: 'customer-service' } },
//   {path: '404', component: NotFoundComponent},
//   {path: 'error', component: ErrorComponent},
//   {path: '**', redirectTo: '/404'}
// ];

@NgModule({
  declarations: [
    AppComponent,
    JourneyPopUpComponent,
    ReviewSelectionComponent,
    SelectImagesComponent,
    NavigationComponent,
    DoAsmtPopupComponent,
    InitialMsgComponent,
    ExistingJourneyPopupComponent,
    MidprogressMsgComponent,
    FinishedMsgComponent,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatDialogModule,
    HttpClientModule,
    CookieModule.forRoot(),
    MatSnackBarModule,
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    },
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 10000}}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
