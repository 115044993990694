import { Injectable } from '@angular/core';

import { SessionInfoService } from "src/app/services/session-info.service";

import { JourneyService } from "src/app/services/journey.service";

import { Router } from '@angular/router';
import { DataHolderService } from "src/app/services/data-holder.service";


@Injectable({
  providedIn: 'root'
})
export class UserAssessmentService {

  private assessmentsTodo;
  private allAsmtQuestions;

  private takenAs;

  private userAssessmentAns = [];

  constructor(
    private sessionService: SessionInfoService, 
    private journeyService: JourneyService,
    private router : Router,
    private dataHolderService: DataHolderService
  ) {}

   /**
   * 
   * This method is currently called by do-asmt-popup-component to start an asmt as a pre or post asmt.
   * In the future, it may be called by another component with the argument 'none' to start an asmt independently of any journey
   */

  routeToAsmt (takenAs) {
    this.setTakenAs(takenAs);
    this.router.navigate(['/assessment']);
  }

  getTakenAs () {
    return this.takenAs;
  }

  setTakenAs (takenAs) {
    this.takenAs = takenAs;
    this.dataHolderService.setD('takenAs', this.takenAs);
  }

  getSessionTakenAs () {
    if(!this.takenAs) {
      if(this.dataHolderService.getD('takenAs')) {
        this.takenAs = this.dataHolderService.getD('takenAs');
        return true;
      }
      return false;
    }
    return true;
  }


  public getAsmtMedia() {
    /**
     *  This method is called in the user assessment component. It grabs the media
     *  needed for the assessment from the backend.
     */

    return this.sessionService.transaction("", "95e9339b-bb7e-44f6-9416-3b36c12d3347", this.journeyService.getUserID())
      .then(data => {
        this.assessmentsTodo = data.get_asmt_media.asmtTypes;
        this.allAsmtQuestions = data.get_asmt_media.asmtQuestions;
      })
  }

  public getAsmtQuestions (asmtID) {
    /**
     *  This method is called in the UserAssessment Component and returns an array of
     *  AsmtQuestion objects related to the current Assessment.
     *
     *  @param asmtID The ID of the current Assessment
     *  @return An Array of AsmtQuestion objects associated with the current assessment
     */
    return this.allAsmtQuestions.filter(obj => obj.asmtID === asmtID);
  }

  public getAssessmentsTodo () {
    /**
     *  This method is called in the UserAssessment Component and returns an array of
     *  Asmt objects for the user to do. It returns a copy of the array, so that the original does not get
     * modified through operations like Array.shift
     *  @return An Array of Asmt objects
     */

    return this.assessmentsTodo.concat();
  }

  public async setAsmtAnswers (ansObjArray) {
    /**
     *  This method is called from the UserAssessment Component and adds to the
     *  asmt res and asmt ans tables in the back end. 
     */
    await this.sessionService.transaction(ansObjArray, "2e01599a-dbfd-405a-8473-500a1baa4ec4", this.journeyService.getUserID());
    await this.createAndUpdateAsmtRes(ansObjArray);
  }
  
  /**
   * This method creates and/or updates asmt res.
   * the datetime is set to the same value as asmt ans. 
   */
  
  async createAndUpdateAsmtRes (ansObjArray) {

    for (let asmt of this.assessmentsTodo) {
      const asmtID = asmt.asmtID;
      let subArray = ansObjArray.filter(ansObj => ansObj.asmtID === asmtID);
      const result = this.calcAssessmentScore(subArray);
      let newResultObj = {};
      newResultObj['asmtID'] = asmtID;
      newResultObj['datetime'] = ansObjArray[0]['datetime'];
      newResultObj['journeyID'] = this.journeyService.getJourneyID();
      newResultObj['usrJourneyID'] = this.journeyService.getUsrJourneyID();
      newResultObj['result'] = result;
      newResultObj['userID'] = this.journeyService.getUserID();
      newResultObj['takenAs'] = this.takenAs;
      await this.sessionService.transaction(newResultObj, "b4525909-64bc-4447-8f64-bd8f0372880e", this.journeyService.getUserID());
    }

  }

  private calcAssessmentScore (subArray) {
    /**
     *  This method calculates the current answer array.
     *
     *  @param subArray The array associated with one asmtID
     *  @return A string denoting the current Assessment's score
     */

    const asmtID = subArray[0]['asmtID'];
    if (asmtID === 101) {
      const presenceArr = [1, 4, 5, 6, 9];
      let presence = 0;
      let search = 0;
      subArray.forEach(ansObj => {
        const id = ansObj.amstQuestionID;
        if (presenceArr.includes(id)) {
          presence += ansObj.answer;
        } else {
          search += ansObj.answer;
        }
      });
      return `Presence: ${presence}, Search: ${search}`;
    }



    const total = subArray.reduce((acc, currAnsObj) => {
      acc+= currAnsObj.answer;
      return acc;
    }, 0);

    return total.toString();

  }

}
