<div class="container">
  <span class="font-bold title" style="color: var(--color-light);">{{deckObject.deckPrompt}}</span>
  <div class="imgList">
    <div class="imageContainer" *ngFor="let k of objectKeys(imageObjects)">
      <img (load)="imageLoaded()"
      id={{imageObjects[k].imgID}} 
      (click)="toggleSelect($event.currentTarget.id)"
      class="image faster-fade-in"
      src="https://d4m4k0kqy6vns.cloudfront.net/media/deck-images/{{deckObject.journeyID}}/{{deckObject.deckID}}/{{imageObjects[k].imagePath}}.jpg">
      <span *ngIf="imageObjects[k].selected" class="material-icons checks">check_circle</span>
    </div>
  </div>
  <div class="container-footer">
    <div class = "text-selected" [ngClass]="{'no-images': noImages}">
      <span>{{numSelected()}}</span>
      <span> (s) images selected.</span>
    </div>
    <div>
      <button class="button margin-right" mat-flat-button color="primary" (click)="onReset()">Reset</button>
      <button class="button" mat-flat-button color="primary" (click)="onReview()">Review Selection</button>
    </div>
  </div>
</div>
